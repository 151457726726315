.company {
    &__container {
        padding-bottom: 16px;

        @include above($md) {
            padding-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            padding-bottom: 59px;
        }
    }

    &__title {
        margin-bottom: 8px;

        @include above($md) {
            margin-bottom: 12px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 24px;
        }
    }

    &__intro-inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include above($md) {
            flex-direction: row;
        }
    }

    &__intro-image-wrapper {
        width: 375px;
        height: 225px;
        margin-bottom: 12px;

        @include above($md) {
            width: 290px;
            height: 174px;
            margin-bottom: 0;
        }

        @include above($old-fashion-lg) {
            width: 342px;
            height: 205px;
        }
    }

    &__intro-image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__intro-info {
        position: relative;
        font-size: 16px;
        line-height: 1.25;

        @include above($md) {
            width: calc(100% - 290px - 20px);
            padding-left: 20px;
            font-size: 18px;
            line-height: 1.33;
        }

        @include above($old-fashion-lg) {
            width: calc(100% - 342px - 67px);
            padding-left: 67px;
            font-size: 28px;
            line-height: 1.42;

            &::after {
                position: absolute;
                top: 0;
                left: 28px;
                content: '';
                width: 10px;
                height: 100%;
                background: repeating-linear-gradient(to bottom, transparent 0 6px, #bfb7b6 4px 11px) 80% / 1.5px 100%
                    no-repeat;
            }
        }
    }

    &__intro {
        margin-bottom: 16px;
        padding-bottom: 20px;

        @include above($md) {
            margin-bottom: 28px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 43px;
        }
    }

    &__content {
        padding-top: 20px;
        background-color: $color-alabaster;

        @include above($md) {
            padding-top: 28px;
        }

        @include above($old-fashion-lg) {
            padding-top: 35px;
            padding-bottom: 52px;
        }
    }

    &__inner-info {
        display: flex;
        flex-direction: column;

        @include above($old-fashion-lg) {
            position: relative;
            width: 857px;
            padding-right: 82px;
            padding-left: 175px;
        }
    }

    &__year {
        font-size: 32px;
        font-weight: $font--semi-bold;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 12px;

        @include above($old-fashion-lg) {
            position: absolute;
            margin: 0;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            background-color: $color-alabaster;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 1.42;

        @include above($old-fashion-lg) {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    &__description-text {
        margin-bottom: 16px;
    }

    &__description-heading {
        font-weight: $font--bold;
    }

    &__item {
        margin-bottom: 24px;

        @include above($md) {
            margin-bottom: 44px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__inner-image-wrapper {
        width: 100%;
        height: auto;
        max-width: 343px;

        @include above($old-fashion-lg) {
            width: 343px;
        }
    }

    &__inner-image {
        width: 100%;
        height: auto;
    }

    &__inner {
        position: relative;

        @include above($old-fashion-lg) {
            &::before {
                position: absolute;
                content: '';
                width: 10px;
                height: calc(100% - 147px - 93px);
                background: repeating-linear-gradient(to bottom, transparent 0 6px, #bfb7b6 4px 11px) 80% / 1.5px 100%
                    no-repeat;
                top: 147px;
                left: 32px;
            }
        }
    }

    &__inner-button-wrapper {
        @include above($old-fashion-lg) {
            width: 343px;
        }
    }
}
