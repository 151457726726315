.vacancy {
    padding-top: 28px;

    @include above($md) {
        padding-top: 32px;
    }

    @include above($old-fashion-lg) {
        padding-top: 35px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__intro-description {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.4;

        @include above($md) {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 1.33;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 36px;
            font-size: 32px;
            line-height: 1.5;
        }
    }

    &__intro-subtitle {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: $font--bold;
        line-height: 1.25;

        @include above($md) {
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 1.2;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 23px;
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__intro-text {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 1.25;

        @include above($md) {
            font-size: 20px;
            line-height: 1.2;
        }

        @include above($old-fashion-lg) {
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__comment-heading {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        @include above($md) {
            margin-bottom: 20px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 28px;
        }
    }

    &__comment-heading-title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: $font--bold;
        line-height: 1.42;

        @include above($md) {
            font-size: 20px;
            line-height: 1.4;
        }

        @include above($old-fashion-lg) {
            font-size: 24px;
            line-height: 1.33;
            margin-bottom: 13px;
        }
    }

    &__comment-heading-position {
        font-size: 14px;
        line-height: 1.42;

        @include above($md) {
            font-size: 20px;
            line-height: 1.4;
        }

        @include above($old-fashion-lg) {
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__comment-heading-image-wrapper {
        width: 96px;
        height: 96px;
        border-radius: 62px;
        overflow: hidden;

        @include above($md) {
            width: 120px;
            height: 120px;
        }

        @include above($old-fashion-lg) {
            width: 156px;
            height: 156px;
        }
    }

    &__comment-heading-image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__comment {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: $color-light-cyan;

        @include above($md) {
            padding-top: 23px;
            padding-bottom: 23px;
        }

        @include above($old-fashion-lg) {
            padding-top: 24px;
            padding-bottom: 26px;
        }
    }

    &__comment-description {
        font-size: 14px;
        line-height: 1.42;

        @include above($md) {
            font-size: 16px;
            line-height: 1.5;
        }

        @include above($old-fashion-lg) {
            column-count: 2;
            column-gap: 40px;
        }
    }

    &__comment-heading-info {
        width: calc(100% - 96px);
        padding-left: 16px;

        @include above($md) {
            width: calc(100% - 120px);
            padding-left: 19px;
        }

        @include above($old-fashion-lg) {
            width: calc(100% - 156px);
            padding-left: 50px;
        }
    }

    &__intro-content {
        position: relative;
        display: flex;
        flex-direction: column;

        @include above($old-fashion-lg) {
            flex-direction: row;
            padding-bottom: 28px;

            &::after {
                position: absolute;
                content: '';
                bottom: 30px;
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(to right, #fff 6px, transparent 1px),
                    linear-gradient(#ccc 2px, transparent 2px);
                background-size: 14px 30px;
            }
        }
    }

    &__intro-footer {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 28px;
        }

        @include above($old-fashion-lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
        }
    }

    &__intro-footer-text {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: $font--bold;
        line-height: 1.33;
        text-transform: uppercase;

        @include above($md) {
            margin-bottom: 16px;
            font-size: 28px;
            line-height: 1.28;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 0;
            margin-right: 19px;
            font-size: 32px;
            line-height: 1.37;
        }
    }

    &__intro-footer-link-wrapper {
        @include above($md) {
            width: 359px;
        }

        @include above($old-fashion-lg) {
            width: 328px;
        }
    }

    &__intro-item {
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 28px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 40px;
        }
    }

    &__intro-content-first {
        @include above($old-fashion-lg) {
            width: 494px;
            margin-right: 111px;
        }
    }

    &__intro-content-second {
        @include above($old-fashion-lg) {
            width: calc(100% - 494px - 111px);
        }
    }

    &__intro-list-item {
        font-size: 16px;
        line-height: 1.25;

        @include above($md) {
            font-size: 20px;
            line-height: 1.2;
        }

        @include above($old-fashion-lg) {
            font-size: 24px;
            line-height: 1.33;
        }
    }
}
