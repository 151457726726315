.vacancies {
    background-color: $color-alabaster;

    &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        @include above($md) {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        @include above($md) {
            margin-bottom: 14px;
        }
    }

    &__title {
        margin-bottom: 12px;

        @include above($md) {
            margin-bottom: 0;
        }
    }

    &__select {
        width: 100%;

        @include above($md) {
            width: 290px;
        }

        @include above($old-fashion-lg) {
            width: 393px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: -8px;

        @include above($md) {
            margin-top: -16px;
        }

        @include above($old-fashion-lg) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -9px;
        }
    }

    &__list-item {
        width: 100%;
        padding: 16px;
        margin-top: 8px;
        background-color: $color-white;
        box-shadow: 0 2px 5px 0 #00000014;
        border-radius: 16px;
        transition: box-shadow 0.2s ease-out;
        color: $color-pearl-beige;

        @include above($md) {
            padding: 24px 32px;
            margin-top: 16px;
        }

        @include above($old-fashion-lg) {
            width: calc(50% - 9px);
            flex-direction: row;
            margin-top: 12px;
            margin-left: 9px;
        }

        &:hover {
            box-shadow: 0 2px 16px 7px #00000014;
        }
    }

    &__card {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__card-info {
        width: 70%;
        display: flex;
        flex-direction: column;

        @include above($md) {
            width: 62%;
        }
    }

    &__card-title {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 1.33;

        @include above($md) {
            margin-bottom: 12px;
            font-size: 32px;
            line-height: 1.5;
        }
    }

    &__card-description {
        font-size: 14px;
        line-height: 1.14;

        @include above($md) {
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__card-image-wrapper {
        width: 68px;
        height: 68px;
        position: relative;
        background-color: $color-alabaster;
        border-radius: 100px;

        @include above($md) {
            width: 160px;
            height: 160px;
        }
    }

    &__card-image {
        display: block;
        width: 100%;
        height: 100%;
    }
}
