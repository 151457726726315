.header {
    padding-top: 25px;
    padding-bottom: 25px;

    @include above($md) {
        padding-top: 26px;
        padding-bottom: 27px;
    }

    @include above($old-fashion-lg) {
        padding-top: 33px;
        padding-bottom: 33px;
        align-items: center;
        justify-content: space-between;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__menu-list {
        display: none;

        @include above($old-fashion-lg) {
            display: flex;
        }
    }

    &__menu-item {
        margin-left: 28px;
    }

    &__menu-item-link {
        font-size: 16px;
        line-height: 1.5;

        &:hover {
            color: $color-emerald;
        }

        &.is-active {
            font-weight: $font--bold;
            color: $color-emerald;
        }
    }

    &__menu-list-self {
        display: flex;
        align-items: center;
    }

    &__logo-wrap {
        width: 92px;
        height: 18px;

        @include above($md) {
            width: 112px;
            height: 22px;
        }
    }

    &__logo {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__menu {
        margin-left: 16px;

        @include above($old-fashion-lg) {
            display: none;
            margin-left: 0;
        }
    }

    &__menu-btn {
        display: block;
    }

    &__menu-line {
        display: block;
        width: 17px;
        height: 3px;
        background-color: $color-emerald;
        border-radius: 10px;

        & + & {
            margin-top: 3px;
        }
    }
}
