// Your custom fonts here
// Example: @include font-face("Lato", "../assets/fonts/Lato/Lato-Regulat", swap, 400, $exts: woff2 woff);
@include font-face(
    'Gotham Pro',
    '../../assets/fonts/GothamPro/GothamPro-Regular',
    swap,
    $font--normal,
    $exts: woff2 woff
);
@include font-face('Gotham Pro', '../../assets/fonts/GothamPro/GothamPro-Bold', swap, $font--bold, $exts: woff2 woff);
@include font-face('Roboto', '../../assets/fonts/Roboto/Roboto-Regular', swap, $font--normal, $exts: woff2 woff);
@include font-face('Roboto', '../../assets/fonts/Roboto/Roboto-Medium', swap, $font--medium, $exts: woff2 woff);
@include font-face('Roboto', '../../assets/fonts/Roboto/Roboto-Bold', swap, $font--bold, $exts: woff2 woff);
