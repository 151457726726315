.return {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 30px;
    color: $color-emerald;
    margin-bottom: 25px;

    @include above($md) {
        margin-bottom: 28px;
        font-size: 24px;
        line-height: 1.33;
    }

    @include above($old-fashion-lg) {
        margin-bottom: 21px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 13px;
        height: 4px;
        background-color: $color-emerald;
        border-radius: 16px;
        transform: rotate(45deg);

        @include above($md) {
            width: 15px;
            bottom: 9px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 13px;
        height: 4px;
        background-color: $color-emerald;
        border-radius: 16px;
        transform: rotate(-45deg);

        @include above($md) {
            top: 10px;
            width: 15px;
        }
    }

    &__text {
        position: relative;
        top: 1px;
        color: inherit;
    }
}
