.solid-btn {
    font-family: $font-stack--extra;
    font-weight: $font--bold;
    width: 100%;
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 60px;
    color: $color-white;
    transition: background-color 0.2s ease-out;

    @include above($md) {
        font-size: 20px;
        padding: 20px 30px;
    }

    @include above($old-fashion-lg) {
        font-size: 24px;
        padding: 30px 40px;
    }

    &--emerald {
        background-color: $color-emerald;

        &:hover {
            background-color: #2f8060;
        }

        &:active {
            background-color: #1d6a4c;
        }
    }

    &--pale {
        color: $color-emerald;
        background-color: $color-light-cyan;

        &:hover {
            background-color: #b4ddd3;
        }

        &:active {
            background-color: #a3d4c9;
        }
    }

    &--special-md {
        @include above($old-fashion-lg) {
            font-size: 20px;
            padding: 21px 30px;
        }
    }

    &:disabled {
        background-color: $color-gallery;
    }
}
