.page-container {
    margin: 0 auto;
    width: 91.46%;

    @include above($md) {
        width: 93.75%;
    }

    @include above($lg) {
        width: 1200px;
    }
}
