/* stylelint-disable */
.introduction {
    color: $color-white;
    background-color: mistyrose;

    &__commentary {
        background-color: $color-emerald;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;

        @include above($md) {
            flex-direction: row;
            align-items: flex-start;
            padding-top: 28px;
            padding-bottom: 28px;
        }

        @include above($old-fashion-lg) {
            padding-top: 56px;
            padding-bottom: 56px;
        }
    }

    &__content-description {
        text-align: center;
        font-size: 16px;
        line-height: 1.5;

        @include above($md) {
            width: calc(100% - 136px - 16px);
            text-align: left;
        }

        @include above($old-fashion-lg) {
            width: calc(100% - 184px - 18px);
            text-align: left;
            font-size: 20px;
            line-height: 1.4;
        }
    }

    &__content-image-wrapper {
        margin-bottom: 16px;
        width: 108px;
        height: 108px;
        overflow: hidden;
        border-radius: 50%;

        @include above($md) {
            width: 136px;
            height: 136px;
            margin-bottom: 0;
            margin-right: 16px;
        }

        @include above($old-fashion-lg) {
            width: 184px;
            height: 184px;
            margin-right: 18px;
        }
    }

    &__content-image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__content-text {
        margin-bottom: 12px;

        @include above($md) {
            margin-bottom: 8px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 22px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__preview-content-text {
        text-align: center;
        margin-bottom: 16px;
        font-weight: $font--bold;
        font-size: 28px;
        line-height: 1.28;

        @include above($md) {
            margin-bottom: 20px;
            font-size: 44px;
            line-height: 1.18;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 44px;
            font-size: 60px;
            line-height: 1.2;
        }
    }

    &__preview-content-link-wrapper {
        width: 100%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        @include above($md) {
            width: 320px;
        }

        @include above($old-fashion-lg) {
            width: 278px;
        }
    }

    &__preview-content-link {
        @include above($old-fashion-lg) {
            padding: 20px 34px;
        }
    }

    &__preview {
        height: calc(100svh - 68px);
        min-height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include above($md) {
            height: calc(100svh - 75px);
        }

        @include above($old-fashion-lg) {
            height: calc(100vh - 90px);
        }
    }

    &__preview-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
        min-height: 300px;
    }

    &__preview-bg {
        display: none;

        @include above($lg) {
            display: block;
            position: absolute;
            left: -9vw;
            top: 10%;
        }
    }
}
