.footer {
    background-color: $color-alabaster;

    &__container {
        padding-top: 24px;
        padding-bottom: 18px;

        @include above($md) {
            padding-top: 28px;
            padding-bottom: 20px;
        }

        @include above($old-fashion-lg) {
            padding-top: 43px;
            padding-bottom: 28px;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;

        @include above($md) {
            padding-bottom: 16px;
        }

        @include above('800px') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 29px;
        }

        &::after {
            position: absolute;
            bottom: 0;
            content: '';
            width: 100%;
            height: 2px;
            background-color: $color-athens-gray;
        }
    }

    &__logo-wrapper {
        width: 112px;
        height: 22px;
        margin-bottom: 24px;

        @include above($md) {
            margin-bottom: 20px;
        }

        @include above('800px') {
            margin-bottom: 0;
        }
    }

    &__logo {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__links-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;

        @include above($md) {
            margin-left: -20px;
        }

        @include above($old-fashion-lg) {
            margin-left: -28px;
        }
    }

    &__links-list-item {
        margin-left: 12px;

        @include above($md) {
            margin-left: 20px;
        }

        @include above($old-fashion-lg) {
            margin-left: 28px;
        }
    }

    &__links-self {
        font-size: 14px;
        line-height: 1.4;

        @include above($old-fashion-lg) {
            font-size: 16px;
            line-height: 1.5;
        }

        &:hover {
            color: $color-emerald;
        }

        &.is-active {
            font-weight: $font--bold;
            color: $color-emerald;
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;

        @include above($md) {
            padding-top: 17px;
        }

        @include above($old-fashion-lg) {
            padding-top: 25px;
        }

        & > a,
        p {
            font-size: 12px;
            line-height: 1.3;
            letter-spacing: 2%;
            margin-right: 32px;
        }
    }

    &__policy-link {
        &:hover {
            color: $color-emerald;
        }
    }
}
