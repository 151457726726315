.ghost-btn {
    width: 100%;
    padding: 12px 22px;
    font-family: $font-stack--extra;
    font-weight: $font--bold;
    font-size: 20px;
    line-height: 20px;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    border-radius: 60px;

    @include above($md) {
        padding: 12px 29px;
    }

    &--emerald {
        border: 1px solid $color-emerald;
        color: $color-emerald;

        &:hover {
            color: #2f8060;
            border-color: #2f8060;
        }

        &:active {
            color: #1d6a4c;
            border-color: #1d6a4c;
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            color: #bfb7b6;
            border-color: #bfb7b6;
        }
    }
}
