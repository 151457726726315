.video {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden;

    @include above($md) {
        height: 388px;
    }

    @include above($old-fashion-lg) {
        height: 700px;
    }

    &__self {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        position: absolute;
        content: '';
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}
