/* stylelint-disable */
.questionnaire {
    padding-top: 20px;
    padding-bottom: 24px;

    @include above($md) {
        padding-top: 28px;
        padding-bottom: 25px;
    }

    @include above($old-fashion-lg) {
        padding-top: 68px;
        padding-bottom: 84px;
    }

    .universal-modal & {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title {
        margin-bottom: 16px;

        @include above($md) {
            margin-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 40px;

            .universal-modal & {
                margin-bottom: 15px;
                font-size: 36px;
                line-height: 1.16;
            }
        }

        .universal-modal & {
            text-align: center;
        }
    }

    &__step-heading {
        display: flex;
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 1.2;
        font-weight: $font--bold;

        @include above($md) {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.33;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 1.5;

            .universal-modal & {
                font-size: 28px;
                line-height: 1.4;
                margin-bottom: 15px;
            }
        }
    }

    &__step-heading-description {
        display: flex;
        align-items: center;
    }

    &__step-heading-value {
        color: $color-emerald;
        margin-right: 8px;
        font-weight: $font--bold;

        @include above($md) {
            margin-right: 16px;
        }

        @include above($old-fashion-lg) {
            margin-right: 16px;
        }
    }

    &__step-heading-image {
        width: 16px;
        height: auto;
        margin-left: 4px;

        @include above($md) {
            width: 19px;
            margin-left: 10px;
        }

        @include above($old-fashion-lg) {
            width: 25px;
            margin-left: 9px;
        }
    }

    &__step {
        margin-bottom: 24px;

        @include above($md) {
            margin-bottom: 28px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 48px;

            &:first-of-type {
                margin-bottom: 30px;

                .universal-modal & {
                    margin-bottom: 20px;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__fields {
        display: flex;
        flex-direction: column;
        margin-top: -8px;
        margin-bottom: 20px;

        @include above($md) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -20px;
            margin-left: -20px;
        }

        @include above($old-fashion-lg) {
            margin-top: 0;
            margin-left: -10px;
        }
    }

    &__field {
        margin-top: 8px;

        @include above($md) {
            width: calc(50% - 20px);
            margin-top: 20px;
            margin-left: 20px;
        }

        @include above($old-fashion-lg) {
            width: 292px;
            margin-top: 0;
            margin-left: 10px;
        }
    }

    &__content-wrapper {
        @include above($old-fashion-lg) {
            display: flex;
            align-items: baseline;

            .universal-modal & {
                align-items: flex-start;
            }
        }
    }

    &__resume-wrapper {
        width: 100%;
        padding: 16px;
        margin-bottom: 24px;
        border: 1px dashed $color-nobel;
        border-radius: 16px;

        @include above($old-fashion-lg) {
            width: 493px;
            margin-left: 98px;
            padding: 42px;
            position: relative;
            top: -25px;

            .universal-modal & {
                top: 0;
            }
        }
    }

    &__content-steps {
        @include above($old-fashion-lg) {
            position: relative;
            top: -40px;

            .universal-modal & {
                top: 0;
            }
        }
    }

    &__field--surname {
        @include above($md) {
            width: 100%;
        }

        @include above($old-fashion-lg) {
            width: 393px;
        }
    }

    &__field--age {
        @include above($md) {
            width: calc(33% - 20px);
        }

        @include above($old-fashion-lg) {
            width: 192px;
        }
    }

    &__field--citizenship {
        @include above($md) {
            width: calc(67% - 20px);
        }

        @include above($old-fashion-lg) {
            width: 293px;
        }
    }

    &__resume-img {
        width: 42px;
        height: 48px;
        margin-bottom: 16px;

        @include above($md) {
            width: 60px;
            height: 69px;
            margin-bottom: 18px;
        }
    }

    &__resume-title {
        margin-bottom: 8px;
        font-weight: $font--bold;
        font-size: 16px;
        line-height: 1.25;

        @include above($old-fashion-lg) {
            margin-bottom: 17px;
            font-size: 24px;
            line-height: 1.16;
        }
    }

    &__resume-text {
        display: block;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 1.25;

        @include above($old-fashion-lg) {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__button-wrapper {
        @include above($md) {
            width: 278px;

            .universal-modal & {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__button {  
        font-size: 20px;
        line-height: 1;

        @include above($old-fashion-lg) {
            padding: 20px 34px;
        }
    }

    &__resume-file-input {
        width: 100%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    &__resume-file {
        display: flex;
        align-items: center;
    }

    &__resume-input-wrapper {
        position: relative;

        @include above($md) {
            width: 180px;
        }
    }

    &__resume-file-formats {
        width: 49%;
        margin-left: 12px;
        font-size: 14px;
        line-height: 1.14;
        color: $color-nobel;

        @include above($md) {
            font-size: 16px;
            line-height: 2;
        }
    }

    &__resume-label {
        font-size: 14px;
        line-height: 1.42;

        @include above($md) {
            font-size: 16px;
            line-height: 1.25;
        }
    }

    &__error {
        margin-top: 12px;
        font-size: 16px;
        line-height: 19px;
        color: $color-tomato-red;
    }

    &__resume-file-name {
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.42;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
