.unordered-list {
    &__item {
        position: relative;
        padding-left: 32px;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 20px;

        @include above($old-fashion-lg) {
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 28px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background-color: $color-emerald;

            @include above($old-fashion-lg) {
                top: 6px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 6px;
            left: 4px;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: $color-white;

            @include above($old-fashion-lg) {
                top: 10px;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
