.contacts {
    &__container {
        padding-bottom: 16px;

        @include above($md) {
            padding-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            padding-bottom: 59px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 20px;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;

        @include above($old-fashion-lg) {
            flex-direction: row;
        }
    }

    &__content {
        margin-bottom: 27px;

        @include above($md) {
            margin-bottom: 16px;
        }

        @include above($old-fashion-lg) {
            width: 72.25%;
            margin-bottom: 0;
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 30px;
        }

        @include above($old-fashion-lg) {
            flex-direction: row;
            align-items: center;
            margin-bottom: 36px;
        }
    }

    &__city-select {
        width: 100%;
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 30px;
        }

        @include above($old-fashion-lg) {
            width: 292px;
            margin-right: 111px;
            margin-bottom: 0;
        }
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__info-elem {
        display: flex;
        width: calc(50% - 5px);
        flex-direction: column;
        margin-left: 5px;

        @include above($old-fashion-lg) {
            width: auto;
            margin-right: 66px;
        }
    }

    &__info-elem-title {
        font-size: 16px;
        line-height: 1.25;
        font-weight: $font--bold;
        margin-bottom: 8px;

        @include above($md) {
            font-size: 18px;
            line-height: 1.11;
        }
    }

    &__info-elem-description {
        font-size: 14px;
        line-height: 1.42;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include above($md) {
            line-height: 1.71;
        }

        @include above($old-fashion-lg) {
            line-height: 1.71;
        }
    }

    &__map {
        position: relative;
        width: 100%;
        height: 380px;
        border-radius: 10px;

        @include above($md) {
            height: 548px;
        }
    }

    &__map-info {
        display: flex;
        flex-direction: column;

        @include above($md) {
            flex-direction: row;
        }

        @include above($old-fashion-lg) {
            flex-direction: column;
            width: calc(27.75% - 44px);
            margin-top: 86px;
            margin-left: 44px;
        }
    }

    &__map-info-inner {
        display: flex;
        flex-direction: column;

        @include between($md, $old-fashion-lg - 1px) {
            width: calc(100% - 187px - 20px);
            margin-left: 20px;
        }
    }

    &__address {
        margin-bottom: 17px;

        @include above($md) {
            margin-bottom: 20px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 24px;
        }
    }

    &__address-marker-wrapper {
        display: flex;
        align-items: center;
    }

    &__address-marker {
        margin-right: 16px;
    }

    &__address-text {
        font-size: 16px;
        line-height: 1.25;

        @include above($md) {
            line-height: 1.5;
        }
    }

    &__map-info-title {
        margin-bottom: 10px;
        font-weight: $font--bold;
        font-size: 20px;
        line-height: 1.4;

        @include above($md) {
            margin-bottom: 6px;
            line-height: 1.5;
        }

        @include above($old-fashion-lg) {
            order: 1;
            margin-bottom: 13px;
            font-size: 24px;
            line-height: 1.33;
        }
    }

    &__map-description {
        font-size: 14px;
        line-height: 1.42;

        @include above($old-fashion-lg) {
            font-size: 16px;
            line-height: 1.5;
            order: 3;
        }
    }

    &__map-info-text {
        font-size: 16px;
        font-weight: $font--bold;
        line-height: 1.5;
    }

    &__map-info-subtile {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        @include above($old-fashion-lg) {
            order: 2;
            margin-bottom: 20px;
        }
    }

    &__map-info-image-wrapper {
        width: 28px;
        height: 28px;
        margin-right: 8px;

        @include above($old-fashion-lg) {
            width: 48px;
            height: 48px;
            margin-right: 4px;
        }
    }

    &__map-info-image {
        width: 100%;
        height: 100%;
    }

    &__map-preview-wrapper {
        margin-bottom: 12px;

        @include above($md) {
            margin-bottom: 0;
            display: none;
        }

        @include above($old-fashion-lg) {
            display: inline-flex;
            margin-bottom: 28px;
            order: 0;
        }
    }

    &__map-info-tablet-preview {
        display: none;

        @include above($md) {
            width: 187px;
            height: 127px;
            display: inline-flex;
        }

        @include above($old-fashion-lg) {
            display: none;
        }

        & > img {
            width: 100%;
            height: 100%;
        }
    }
}
