@charset "UTF-8";

// Setup
@import '../setup/setup';
@import '../blocks/root';
@import '../blocks/page';
@import '../blocks/page-container';
@import '../blocks/container';
@import '../blocks/visuallyhidden';
@import '../blocks/title';
@import '../blocks/highlighted-text-emerald';
@import '../blocks/link';
@import '../blocks/button/btn';
@import '../blocks/button/ghost-btn';
@import '../blocks/button/solid-btn';
@import '../blocks/unordered-list';
@import '../blocks/return';
@import '../blocks//selected-background-emerald';
@import '../blocks/d-none';
@import '../blocks/field';
@import '../blocks/universal-modal';
@import '../blocks/footer';
@import '../blocks/header';
@import '../blocks/mobile-menu';
@import '../blocks/mobile-menu-overlay';
// Sections
@import '../sections/introduction';
@import '../sections/features';
@import '../sections/video';
@import '../sections/vacancies';
@import '../sections/tour';
@import '../sections/contacts';
@import '../sections/company';
@import '../sections/vacancy';
@import '../sections//questionnaire';
