/* stylelint-disable */
.field {
    position: relative;

    &__input {
        position: relative;
        width: 100%;
        font-family: $font-stack--extra;
        font-weight: $font--normal;
        font-size: 16px;
        line-height: 19px;
        padding: 21px 28px 20px 28px;
        transition: border-color 0.25s ease-in-out;
        color: $color-pearl-beige;
        border-radius: 16px;
        border: 1px solid transparent;
        background-color: #f8f8f8;
        caret-color: $color-emerald;

        &:focus {
            border-color: $color-emerald;
        }

        &::placeholder {
            color: #bfb7b6;

            .field.has-error & {
                color: $color-tomato-red;
            }
        }

        &:focus::placeholder {
            color: transparent;

            .field.has-error & {
                color: transparent;
            }
        }

        .field.has-error & {
            color: $color-tomato-red;
            border: 1px solid $color-tomato-red;
            border-radius: 16px;
            background-color: #ffc2bd;
        }
    }

    &__message {
        display: none;
        margin-top: 12px;
        font-size: 16px;
        line-height: 19px;
        color: $color-tomato-red;

        .field.has-error & {
            display: block;
        }
    }
}
