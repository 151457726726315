.root {
    font-family: $font-stack--base, sans-serif;
    font-size: $fs;
    line-height: $lh;
    font-weight: $font--medium;
    background: $color-white;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    min-width: $width--min;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
    -webkit-overflow-scrolling: touch;
    color: $color--text;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
