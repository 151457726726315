.link {
    font-family: $font-stack--base;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease-out;

    &--emarald {
        color: $color-emerald;
    }
}
