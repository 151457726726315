.features {
    &__title {
        margin-bottom: 8px;
        text-align: center;

        @include above($md) {
            margin-bottom: 16px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 34px;
        }
    }

    &__text {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;

        @include above($old-fashion-lg) {
            margin-bottom: 26px;
            font-size: 24px;
            line-height: 1.3;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-top: 20px;

        @include above($md) {
            width: 33.3%;
        }

        @include above($old-fashion-lg) {
            margin-top: 30px;
        }
    }

    &__image-wrapper {
        width: 77px;
        height: 62px;
        margin-bottom: 18px;

        @include above($old-fashion-lg) {
            margin-bottom: 6px;
        }

        @include above($old-fashion-lg) {
            width: 100px;
            height: 76px;
            margin-bottom: 10px;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__info {
        font-size: 16px;
        line-height: 1.25;
        text-align: center;

        @include above($old-fashion-lg) {
            font-size: 24px;
            line-height: 1.33;
        }
    }
}
