body {
    height: 100%;
    -webkit-overflow-scrolling: touch;

    & *:focus {
        outline: none;
    }

    &.ov-hidden {
        overflow: hidden;
    }
}
