.mobile-menu-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(11, 11, 11, 0.7);
    mix-blend-mode: normal;
    backdrop-filter: blur(24px);
    z-index: 2;
    height: 0;
    overflow: hidden;
    opacity: 0;
    will-change: opacity;
    transition-property: height, opacity;
    transition-duration: 0ms, 750ms;
    transition-delay: 550ms, 0ms;

    &.is-active {
        height: 100vh;
        opacity: 1;
        transition-property: height, opacity;
        transition-delay: 0ms, 0ms;
    }

    @include above($lg) {
        display: none;
    }
}
