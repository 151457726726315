.container {
    padding-top: 20px;
    padding-bottom: 20px;

    @include above($md) {
        padding-top: 22px;
        padding-bottom: 22px;
    }

    @include above($old-fashion-lg) {
        padding-top: 68px;
        padding-bottom: 68px;
    }
}
