.title {
    color: inherit;
    font-weight: $font--bold;

    &--h1 {
        font-size: 24px;
        line-height: 28px;

        @include above($md) {
            font-size: 44px;
            line-height: 56px;
        }

        @include above($old-fashion-lg) {
            font-size: 60px;
            line-height: 72px;
        }
    }

    &--h2 {
        font-size: 23px;
        line-height: 30px;

        @include above($md) {
            font-size: 32px;
            line-height: 44px;
        }

        @include above($old-fashion-lg) {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &--h3 {
        font-weight: $font--normal;
        font-size: 20px;
        line-height: 24px;

        @include above($md) {
            font-size: 24px;
            line-height: 32px;
        }

        @include above($old-fashion-lg) {
            font-size: 28px;
            line-height: 40px;
        }
    }

    &--black {
        color: $color-almost-black;
        font-size: 36px;
        line-height: 40px;

        @include above($md) {
            font-size: 56px;
            line-height: 68px;
        }

        @include above($old-fashion-lg) {
            font-size: 60px;
            line-height: 72px;
        }
    }
}
