.universal-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;

    &.is-active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        @include above($md) {
            padding-top: 32px;
            padding-bottom: 48px;
        }

        @include above($old-fashion-lg) {
            padding-top: 48px;
            padding-bottom: 48px;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        background: rgba(11, 11, 11, 0.7);
        mix-blend-mode: normal;
        backdrop-filter: blur(24px);
    }

    &__content {
        position: relative;
        padding: 16px;
        background-color: $color-white;
        height: 100%;
        overflow-y: auto;

        @include above($md) {
            padding: 20px;
            border-radius: 16px;
        }
    }

    &__close-btn {
        position: relative;
        width: 16px;
        height: 16px;
        margin-left: auto;
        cursor: pointer;

        @include above($md) {
            width: 20px;
            height: 20px;
        }

        @include above($old-fashion-lg) {
            width: 24px;
            height: 24px;
        }
    }

    &__close-btn-icon {
        display: block;
        width: 100%;
        height: 100%;
    }
}
