/* stylelint-disable */
.mobile-menu {
    font-size: 16px;
    line-height: 1.5;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    height: 0;
    overflow-y: auto;
    background-color: $color-white;
    width: 0;
    transform: translateX(-300%);
    will-change: transform;
    transition-property: transform;
    transition-duration: 350ms;
    transition-timing-function: ease-in-out;

    &.is-active {
        width: 100%;
        height: 100svh;
        min-height: 600px;
        transform: translateX(0);
    }

    @include above($lg) {
        display: none;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 16px;

        @include above($md) {
            padding: 79px;
        }
    }

    &__close-btn {
        position: absolute;
        top: 19px;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-left: auto;

        @include above($md) {
            top: 22px;
            right: 26px;
        }
    }

    &__close-btn-icon {
        display: block;
        width: 19px;
        height: 19px;
    }

    &__logo-wrapper {
        width: 116px;
        height: 31px;
        margin-bottom: 56px;

        @include above($md) {
            width: 300px;
            height: 59px;
        }
    }

    &__logo {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__list { 
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    &__item {
        margin-bottom: 22px;
        
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__footer-list {
        display: flex;
        margin-top: -10px;
        margin-left: -10px;
        flex-wrap: wrap;

        @include above($md) {
            margin-top: -14px;
            margin-left: -14px;
        }
    }

    &__footer-item {
        margin-top: 10px;
        margin-left: 10px;

        @include above($md) {
            margin-top: 14px;
            margin-left: 14px;
        }
    }

    &__footer-image {
        display: block;
        width: 106px;
        height: 32px;

        @include above($md) {
            width: 152px;
            height: 45px;
        }
    }

    &__link {
        padding: 6px;
        &.is-active {
            font-weight: $font--bold;
            color: $color-emerald;
        }
    }
}
