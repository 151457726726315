.tour {
    &__container {
        padding-bottom: 16px;

        @include above($md) {
            padding-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            padding-bottom: 59px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @include above($md) {
            margin-bottom: 24px;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 20px;
        }
    }

    &__description {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.4;

        @include above($md) {
            margin-bottom: 24px;
            font-size: 32px;
            line-height: 1.33;
        }

        @include above($old-fashion-lg) {
            margin-bottom: 36px;
            font-size: 32px;
            line-height: 1.5;
        }
    }

    &__content {
        position: relative;
        height: 352px;

        @include above($md) {
            height: 430px;
        }

        @include above($old-fashion-lg) {
            height: 550px;
        }

        @include above($lg) {
            height: 726px;
        }
    }

    &__iframe-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 10px;
    }
}
