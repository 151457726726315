.selected-background-emerald {
    position: relative;
    left: -7px;
    background-color: $color-light-cyan;
    padding: 7px 5px 6px;

    @include above($md) {
        padding: 5px 6px;
    }
}
